import './index.css';

const DiscoverAndCompare =()=>{
    return(
        <>
            <div class="discoverAndCompare-container">
                <img class="discoverAndCompare-image" src={require('../../../assets/images/DiscoverAndCompare.png')}  alt="Company Logo" />
                <div class="discoverAndCompare-sub-container">
                    <div class="discoverAndCompare-sub-sub-container">
                        <div class="discoverAndCompare-sub-sub-sub-container">
                            <div class="discoverAndCompare-text-heading">DISCOVER</div>
                            <div class="discoverAndCompare-text-sub-heading">Top- rated Global Products</div>
                        </div>
                    </div>
                    <div class="discoverAndCompare-sub-sub-container">
                        <div class="discoverAndCompare-sub-sub-sub-container">
                            <div class="discoverAndCompare-text-heading">COMPARE</div>
                            <div class="discoverAndCompare-text-sub-heading">Prices across 50+ websites</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DiscoverAndCompare