import React from "react";
import "./index.css";

const TDPRecommends = () => {
  return (
    <>
    <div class="tdp-recommends-headings">TDP Recommends</div>
    <div class="tdp-recommends-container">
      <img
        src={require("../../../assets/images/TDP_Recommends.png")}
        alt="Description"
        class="tdp-recommends-image"
      />
      <div class="tdp-recommends-text-container">
        <div class="tdp-recommends-featured-text">FEATURED</div>
        <div>
          <div class="tdp-recommends-brand-name">SPOILED CHILD</div>
          <div class="tdp-recommends-product-name">
            G25+ Anti-aging Retin + Night Rewind Serum
          </div>
        </div>
        <div class="tdp-featured-product-quantity">200 ML/ 6.8 Oz</div>
        <div class="tdp-recommends-product-description">
          This powerhouse serum has been expertly formulated to work hard while
          you sleep. With a unique pairing of Retinol and....
        </div>
        <div class="tdp-recommends-button">
          KNOW MORE
        </div>
      </div>
    </div>
    </>
  );
};

export default TDPRecommends;
