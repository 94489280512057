import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import './header.css';

const Header = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => {
      setMenuOpen(!menuOpen);
    };

    return (
        <>
        <header class="header-container">
            <div onClick={()=>{
                navigate('/')
            }} style={{cursor: 'pointer'}}>
                <img class='header-logo' src={require('../../assets/images/TDP_Logo.png')}  alt="Company Logo"/>
            </div>
            <div class="header-company-name">
                The Dew Project
            </div>
            <div class="header-menu-container">
            {!menuOpen && <div className="header-menu-icon" onClick={handleMenuToggle}>
             &#9776;
            </div>}
            {menuOpen && <div className="header-menu-icon" onClick={handleMenuToggle}>
              &#x2715;
            </div>}
            {menuOpen && (
              <div className="dropdown-menu" style={dropdownMenuStyle}>
                {/* Add your menu items here */}
                <div>SIGN UP/ LOG IN</div>
                <div>CONTACT US</div>
                <div style={{height: '1px', width: '100%', backgroundColor: '#000'}}></div>
                <div>ABOUT US</div>
                <div>ADVERTISE WITH US</div>
                <div>OUR BLOG</div>
              </div>
            )}
          </div>
        </header>
        <div style={{height: 1, backgroundColor: '#000', width: '100%', marginBottom:'20px'}}></div>
        </>
    );
};

const dropdownMenuStyle = {
  position: 'absolute',
  top: '100%', // Position just below the menu icon
  right: '25px',
  backgroundColor: '#FFF',
  color: '#000',
  padding: 10,
  display: 'flex',
  flexDirection: 'column', 
  width: '200px',
  height: '180px',
  alignItems: 'center',
  justifyContent : 'space-between',
  boxShadow: '-10px 10px 45px rgba(207, 199, 199, 0.75)',
  borderRadius: '12px',
  zIndex: 1,
};

export default Header;