import './index.css';

const DiscoverBrands =()=>{
    return(
        <>
            <div class="discover-brands-container">
                    <img class="discover-brands-image1" src={require('../../../assets/images/DBImage1.png')}  alt="Company Logo" />
                    <img class="discover-brands-image2" src={require('../../../assets/images/DBImage2.png')}  alt="Company Logo" />
                    <img class="discover-brands-image3" src={require('../../../assets/images/DBImage3.png')}  alt="Company Logo" />
                    <div class="discover-brands-text">Discover brands like never before</div>
            </div>
        </>
    )
}

export default DiscoverBrands