import React from 'react';
import './searchBar.css'; // Import your CSS file

const SearchBar = () => {

  return (
    <div className="main-container">
        <div className="search-bar-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M19.6797 17.8918L15.9979 14.2205C17.1858 12.707 17.8303 10.838 17.828 8.91391C17.828 7.20036 17.3199 5.52528 16.368 4.10052C15.4161 2.67575 14.0631 1.56528 12.4801 0.909532C10.8972 0.253784 9.15535 0.0822108 7.47489 0.416508C5.79443 0.750806 4.25083 1.57596 3.03929 2.78762C1.82775 3.99929 1.00268 5.54304 0.668414 7.22367C0.33415 8.9043 0.505707 10.6463 1.16139 12.2294C1.81707 13.8125 2.92743 15.1657 4.35205 16.1177C5.77667 17.0697 7.45158 17.5778 9.16496 17.5778C11.0889 17.5802 12.9577 16.9356 14.471 15.7475L18.142 19.4297C18.2427 19.5312 18.3624 19.6118 18.4944 19.6667C18.6263 19.7217 18.7679 19.75 18.9108 19.75C19.0538 19.75 19.1953 19.7217 19.3273 19.6667C19.4592 19.6118 19.579 19.5312 19.6797 19.4297C19.7812 19.329 19.8617 19.2092 19.9167 19.0773C19.9717 18.9453 20 18.8037 20 18.6608C20 18.5178 19.9717 18.3762 19.9167 18.2443C19.8617 18.1123 19.7812 17.9925 19.6797 17.8918ZM2.66771 8.91391C2.66771 7.62874 3.04876 6.37244 3.76269 5.30387C4.47662 4.23529 5.49135 3.40244 6.67857 2.91063C7.86578 2.41882 9.17216 2.29014 10.4325 2.54086C11.6928 2.79158 12.8505 3.41045 13.7592 4.3192C14.6679 5.22794 15.2867 6.38576 15.5374 7.64623C15.7881 8.9067 15.6594 10.2132 15.1676 11.4005C14.6759 12.5879 13.8431 13.6027 12.7746 14.3167C11.7062 15.0307 10.45 15.4118 9.16496 15.4118C7.44178 15.4118 5.78918 14.7272 4.57071 13.5086C3.35224 12.29 2.66771 10.6373 2.66771 8.91391Z" fill="black"/>
            </svg>
          <input type="text" className="search-input" placeholder="Search any product">
          </input>        
        </div>
    </div>
  );
};

export default SearchBar;
