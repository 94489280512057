import React from "react";
import './footer.css';

const Footer = () => {

  return (
    <>
      <div class="footer-container">
        <div class="footer-tdp-container">
          <img
            class="footer-tdp-logo"
            src={require("../../assets/images/TDP_Logo.png")}
            alt="Company Logo"
          />
          <div class="footer-tdp-name">The Dew Project</div>
        </div>
        <div class="footer-link-container">
            <div class="footer-link-subcontainer-left">
                <div class="footer-link-left">ABOUT US</div>
                <div class="footer-link-left">ADVERTISE WITH US</div>
                <div class="footer-link-left">CONTACT US</div>
            </div>
            <div style={{height: '100%', width: '1px', backgroundColor: '#000'}}></div>
            <div class="footer-link-subcontainer-right">
                <div class="footer-link-right">SIGN UP</div>
                <div class="footer-link-right">TERMS & CONDITIONS</div>
                <div class="footer-link-right">PRIVACY POLICY</div>
            </div>
        </div>
        <div class="footer-copyright">Copyright @ 2024 reserved</div>
      </div>
    </>
  );
};

// Styles

export default Footer;
