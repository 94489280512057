import SearchBar from '../../components/searchbar/searchBar';
import DiscoverBrands from './DiscoverBrands';
import DiscoverAndCompare from './DiscoverAndCompare';
import TDPRecommends from './TDPRecommends';
import TDPPerspective from './TDPPerspective';
import SignUpBanner from './SignUpBanner';
import MegaMenu from '../../components/MegaMenu';

const HomeScreen = () => {
    return (
      <>
        <MegaMenu/>
        <SearchBar/>
        <DiscoverBrands/>
        <DiscoverAndCompare/>
        <TDPRecommends/>
        <TDPPerspective/>
        <SignUpBanner/>
      </>
    );
  };
  
export default HomeScreen;