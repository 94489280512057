import React from "react";
import "./index.css";

const TDPPerspective = () => {
  return (
    <>
      <div class="tdp-perspective-headings">TDP Perspective</div>
      <div class="tdp-perspective-line"/>
      <div class="tdp-perspective-container">
      <div class="tdp-perspective-sub-container">
          <img
            src={require("../../../assets/images/TDP_Perspective.png")}
            alt="Description"
            class="tdp-perspective-image"
          />
          <div class="tdp-perspective-type">SKINCARE</div>
          <div class="tdp-perspective-title">All the upcoming skincare trends in 2024</div>
        </div>
        <div class="tdp-perspective-sub-container">
          <img
            src={require("../../../assets/images/TDP_Perspective.png")}
            alt="Description"
            class="tdp-perspective-image"
          />
          <div class="tdp-perspective-type">SKINCARE</div>
          <div class="tdp-perspective-title">All the upcoming skincare trends in 2024</div>
        </div>
        <div class="tdp-perspective-sub-container">
          <img
            src={require("../../../assets/images/TDP_Perspective.png")}
            alt="Description"
            class="tdp-perspective-image"
          />
          <div class="tdp-perspective-type">SKINCARE</div>
          <div class="tdp-perspective-title">All the upcoming skincare trends in 2024</div>
        </div>
      </div>
    </>
  );
};

export default TDPPerspective;
