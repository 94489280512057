import React from 'react';
import './index.css';

const MegaMenu = ()=>{
    return(
        <div class="mega-menu-container">
            <div class="mega-menu-category">
                Makeup
            </div>
            <div class="mega-menu-category">
                Skincare
            </div>
            <div class="mega-menu-category">
                Bodycare
            </div>
            <div class="mega-menu-category">
                Haircare
            </div>
            <div class="mega-menu-category">
                Fragrances
            </div>
            <div class="mega-menu-category">
                Appliances
            </div>
        </div>
    )
}

export default MegaMenu;