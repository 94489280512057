import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import './layout.css';

const Layout = () => {
  return (
    <>
    <div class="container">
          <Header />
        <div class="content">
          <Outlet />
        </div>
          <Footer />
      </div>
    </>
  );
};

export default Layout;
