import React from "react";
import "./index.css";

const SignUpBanner = () => {
  return (
    <>
      <div class="tdp-signupbanner-container">
      <div class="tdp-signupbanner-sub-container-1">
          <img
            src={require("../../../assets/images/SignUpBanner1.png")}
            alt="Description"
            class="tdp-perspective-image"
          />
        </div>
        <div class="tdp-signupbanner-sub-container-2">
         <div class="tdp-signupbanner-title-1">
         What are you waiting for?
         </div>
         <div class="tdp-signupbanner-title-2">
         Sign up to get everything beauty delivered right to your inbox.
         </div>
         <div class="tdp-signupbanner-button">
         JOIN US
         </div>
        </div>
        <div class="tdp-signupbanner-sub-container-3">
          <img
            src={require("../../../assets/images/SignUpBanner2.png")}
            alt="Description"
            class="tdp-perspective-image"
          />
        </div>
      </div>
    </>
  );
};

export default SignUpBanner;
